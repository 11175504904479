import React, { type FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { withLogin } from '../helpers'
import { ExplanationProvider, ReferencesProvider } from '../hocs'
import { ChatWrapper } from '../wrappers'
import { Login } from './Login'
import { Typeform } from './Typeform'

export const Screens: FC = () => (
  <Switch>
    {withLogin ? (
      <Route exact path="/demo/login" component={Login} />
    ) : (
      <Route exact path="/demo/login" component={Typeform} />
    )}
    <Route exact path="/demo/chat/:scenario_slug">
      <ReferencesProvider>
        <ExplanationProvider>
          <ChatWrapper />
        </ExplanationProvider>
      </ReferencesProvider>
    </Route>

    <Redirect from="*" to="/demo/chat/default" />
  </Switch>
)
