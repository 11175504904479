import {
  Divider,
  FormControlLabel,
  LinearProgress,
  Switch,
  Typography,
} from '@mui/material'
import React, { type FC, useContext } from 'react'

import { ExplanationContext } from '../../contexts'

type List = Array<{
  title: string
  moderation?: string
  content: Array<{
    key: string
    value: string | undefined
  }>
}>

export const Explanation: FC = () => {
  const { explanationLoading, data, visible, setVisible } =
    useContext(ExplanationContext)
  if (explanationLoading && visible) return <LinearProgress />
  if (!data) return <></>
  let list: List = []

  if (data.extra && 'search_list' in data.extra) {
    list = data.extra.search_list.map((list, index) => ({
      title: `Search ${index + 1}`,
      content: [
        { key: 'Search query', value: list.search_query },
        { key: 'Search section', value: list.search_section },
        { key: 'Search topic list', value: list.search_topic_list?.join(', ') },
      ],
    }))
  } else if (data.extra && 'classification_list' in data.extra) {
    list = [
      {
        title: '',
        moderation: data.extra.moderation,
        content: [
          {
            key: 'Classification list',
            value: data.extra.classification_list?.join(', '),
          },
          {
            key: 'Program information topic list',
            value: data.extra.program_information_topic_list?.join(', '),
          },
        ],
      },
    ]
  }

  const controlVisibility = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setVisible(event.target.checked)
  }

  return (
    <>
      {data.explanation && (
        <>
          <FormControlLabel
            control={<Switch checked={visible} onChange={controlVisibility} />}
            label="Display classification explanation"
          />
          {visible && (
            <>
              <Typography variant="h6">Classification explanation:</Typography>

              <Typography variant="body1" align="justify">
                {data.explanation}
              </Typography>
              {data.extra &&
                'moderation' in data.extra &&
                data.extra.moderation && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Moderation:</span>{' '}
                    {data.extra.moderation}
                  </Typography>
                )}

              {data.extra && list[0] && 'search_list' in data.extra && (
                <Typography variant="h6">Search List:</Typography>
              )}
              {list.map((listItem, index) => (
                <div key={listItem.title || 'key'}>
                  {listItem.title && (
                    <Typography
                      variant="body1"
                      align="center"
                      fontWeight="bold"
                      paragraph
                    >
                      {listItem.title}
                    </Typography>
                  )}
                  {listItem.content.map(
                    ({ key, value }) =>
                      value && (
                        <Typography
                          key={`${listItem.title} - ${key}`}
                          variant="body1"
                        >
                          <span style={{ fontWeight: 'bold' }}>{key}:</span>{' '}
                          {value}
                        </Typography>
                      ),
                  )}
                  {list.length > index + 1 && (
                    <>
                      <br />
                      <Divider />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  )
}
