import { Paper, styled } from '@mui/material'

import { type components } from '../../configs/schema'

export const Message = styled(Paper)<{
  role: components['schemas']['APIMessage']['role']
}>(({ theme, role }) => ({
  maxWidth: '600px',
  borderRadius: '10px',
  padding: theme.spacing(2),

  '& a': {
    color: theme.palette.primary.contrastText,
    // textDecoration: 'none',
    fontWeight: '500',
  },
  ...(role === 'assistant'
    ? {
        alignSelf: 'flex-start',
        marginRight: '20%',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }
    : {}),
  ...(role === 'user'
    ? {
        alignSelf: 'flex-end',
        marginLeft: '20%',
        color: theme.palette.text.primary,
      }
    : {}),
  ...(role === 'system'
    ? {
        textAlign: 'center',
        maxWidth: '100%',
        width: '100%',
        alignSelf: 'center',
        background: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderRadius: '25px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        '>p': {
          fontSize: '18px',
        },
      }
    : {}),
}))
