import { type components } from '../configs/schema'

interface Legend {
  name?: string
  current_time?: string
  demographic?: Array<{ label: string; value: string }>
  psychographic?: Array<{ label: string; value: string }>
  customList?: Array<{ label: string; value: string }>
}

export const enrollment: Legend = {
  name: 'John Doe',
  current_time: '1pm on May 11, 2023',
  demographic: [
    { label: 'Condition', value: 'Stress and Anxiety Disorder' },
    { label: 'Gender', value: 'Male' },
    { label: 'Age', value: '45' },
    { label: 'Marital Status', value: 'Divorced' },
    { label: 'Occupation', value: 'Sales Executive' },
    { label: 'Education', value: "Master's degree" },
    { label: 'Income', value: '$120,000 per year' },
    { label: 'Location', value: 'Suburban' },
  ],
  psychographic: [
    { label: 'Personality', value: 'Impulsive, charismatic, and adventurous' },
    { label: 'Interests', value: 'Traveling, extreme sports, and music' },
    { label: 'Lifestyle', value: 'Fast-paced, sociable, and hedonistic' },
    { label: 'Values', value: 'Freedom, excitement, and success' },
    {
      label: 'Triggers',
      value:
        'Stressful work situations, financial problems, and relationship issues',
    },
  ],
}

export const insomnia: Legend = {
  name: 'John Doe',
  current_time: '2am on May 11, 2023',
}

export const studyUpdate: Legend = {
  customList: [
    { label: 'Name', value: 'Carla' },
    { label: 'Drug', value: 'Ozelta' },
    { label: 'Clinical trial duration', value: '18 months' },
    { label: 'Participant’s time in trial', value: '9 months' },
    { label: 'Major side effects', value: 'none' },
    {
      label: 'Minor side effects',
      value:
        'headache (reported twice). Headache: common size effect experienced by 12 percent of early clinical trial participants.',
    },
    {
      label: 'Next appointment',
      value:
        'September 18, 2023 at 9am with Dr. Amy Jones. Address: 201 Mission St Suite 101, San Francisco, CA 94105',
    },
  ],
}

export const legends: Partial<
  Record<components['schemas']['APIScenario']['slug'], Legend>
> = {
  enrollment,
  insomnia,
  study_update: studyUpdate,
}
