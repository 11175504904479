import { createContext } from 'react'

import { type components } from '../configs/schema'

export interface ReferencesContextType {
  data: components['schemas']['APIReferences']
  refreshToken: () => void
}

export const ReferencesContext = createContext<ReferencesContextType>(
  undefined!,
)
