import React, { type FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ErrorsContext,
  type ErrorsContextType,
  SystemNotificationsContext,
} from '../contexts'
import { deleteJWT } from '../helpers'

export enum StatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
}

interface ResponseType {
  response: {
    body: ReadableStream
    bodyUsed: boolean
    headers: Headers
    ok: boolean
    redirected: boolean
    status: StatusCode
    statusText: string
    type: string
    url: string
  }
  error: {
    detail: string
  }
}

export const ErrorsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const history = useHistory()
  const { sendErrorNotification } = useContext(SystemNotificationsContext)

  const handleError = (response: ResponseType): void => {
    const loginPageURL = '/demo/login'
    if (response.response || response.error) {
      switch (response.response.status) {
        case StatusCode.Unauthorized:
          deleteJWT()
          history.replace(loginPageURL)
          response.error.detail !== 'Unauthorized' &&
            sendErrorNotification(response.error.detail)
          break
        default:
          // TODO: Style toast bars
          sendErrorNotification(response.error.detail)
      }
    }
  }

  const dataBag: ErrorsContextType = { handleError }
  return (
    <ErrorsContext.Provider value={dataBag}>{children}</ErrorsContext.Provider>
  )
}
