import { Button, Paper, styled, TextField, Typography } from '@mui/material'
import React, {
  type FC,
  type FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

import { logoImage } from '../assets'
import { post } from '../configs'
import { ErrorsContext } from '../contexts'
import { getJWT, setJWT } from '../helpers'

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  gap: theme.spacing(3),
  background: 'linear-gradient(to bottom, #7366FF, #BBA6FF, #FFD1AA)',

  '& > div': {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(4),
    '& > img': {
      margin: `-${theme.spacing(4)}`,
      height: theme.spacing(21),
      padding: theme.spacing(4),
      background: theme.palette.primary.dark,
      marginBottom: theme.spacing(3),
    },
    '& > button': {
      marginTop: theme.spacing(3),
    },
  },
}))

export const Login: FC = () => {
  const { handleError } = useContext(ErrorsContext)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getJWT().access_token && history.replace('/demo/chat')
  }, [])

  const login = (email: string, password: string): void => {
    setLoading(true)
    if (localStorage.getItem('email') !== email) {
      localStorage.clear()
    }
    post('/api/demo/auth/token', {
      body: {
        email,
        password,
      },
    }).then((response) => {
      if (!response.error) {
        const { access_token, refresh_token, expires_in } = response.data
        localStorage.setItem('email', email)
        setJWT(access_token, refresh_token, expires_in)
        history.replace('/demo/chat')
      } else {
        handleError(response)
      }
      setLoading(false)
    })
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const email = target.email.value
    const password = target.password.value

    login(email, password)
  }

  if (getJWT().access_token) return <></>

  return (
    <Form onSubmit={handleSubmit}>
      <Paper elevation={3}>
        <img src={logoImage} alt="logo" />
        <Typography variant="h6" align="center">
          Login to Demo
        </Typography>
        <TextField
          name="email"
          disabled={loading}
          autoFocus
          required
          label="Email"
          variant="outlined"
          type="email"
        />
        <TextField
          name="password"
          disabled={loading}
          required
          label="Password"
          variant="outlined"
          type="password"
        />
        <Button
          size="large"
          disabled={loading}
          variant="contained"
          type="submit"
        >
          Login
        </Button>
      </Paper>
    </Form>
  )
}
