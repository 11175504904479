import { Typography } from '@mui/material'
import React, { type FC } from 'react'
import { useParams } from 'react-router-dom'

import { legends } from '../../mocks/legends'
import { type ChatParams } from '../../screens/Chat'

export const Legend: FC = () => {
  const { scenario_slug } = useParams<ChatParams>()
  const legend = legends[scenario_slug]
  if (!legend) return <></>

  return (
    <>
      {legend.name && (
        <>
          <Typography variant="h6">Patient information:</Typography>
          <Typography variant="body1">Name: {legend.name}</Typography>
          <Typography variant="body1" paragraph>
            Current Time: {legend.current_time}
          </Typography>
        </>
      )}
      {legend.demographic && (
        <>
          <Typography variant="h6">Demographic information:</Typography>
          {legend.demographic.map((dmg) => (
            <Typography key={dmg.label} variant="body1">
              <b>{dmg.label}:</b> {dmg.value}
            </Typography>
          ))}
          <br />
        </>
      )}

      {legend.psychographic && (
        <>
          <Typography variant="h6">Psychographic information:</Typography>
          {legend.psychographic.map((psh) => (
            <Typography key={psh.label} variant="body1">
              <b>{psh.label}:</b> {psh.value}
            </Typography>
          ))}
          <br />
        </>
      )}

      {legend.customList && (
        <>
          <Typography variant="h6">Information:</Typography>
          {legend.customList?.map((cl) => (
            <Typography key={cl.label} variant="body1">
              <b>{cl.label}:</b> {cl.value}
            </Typography>
          ))}
        </>
      )}
    </>
  )
}
