import { type components } from '../configs/schema'

type Context = components['schemas']['APIScenarioConversation']

const scheduling: Context = {
  last_modified_at: '2023-05-29T19:31:12.067774+00:00',
  created_at: '2023-05-29T18:21:51.014659+00:00',
  session_duration: 45,
  uid: 'f7f7eed2-d33f-4045-80cb-ba84f7a6e137',
  scenario: {
    description:
      'The co-pilot determines that a patient has no upcoming coaching session scheduled and initiates a conversation to schedule it.',
    short_title: 'Scheduling',
    long_title: 'A missing next session',
    title: 'Scheduling a Missing Next Session',
    slug: 'scheduling',

    example_patient_messages: [
      'Anything on Thursday or Friday?',
      'How is Friday afternoon?',
      'How is further out?',
      'Can you do on Tuesday at 3?',
    ],
    is_default: true,
  },
  patient: {
    timezone: '',
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    uid: 'a5b7949d-e21a-4f22-8c49-b0e6d9ae3ce0',
  },
  coach: {
    first_name: 'Jane',
    last_name: 'Jones',
    full_name: 'Jane Jones',
    uid: 'b5aa0710-42e5-462b-b4c0-daaa60f85339',
    availability: [
      { start: '2023-04-17T13:00:00', end: '2023-04-17T14:00:00' },
      { start: '2023-04-18T11:00:00', end: '2023-04-18T13:00:00' },
      { start: '2023-04-18T14:30:00', end: '2023-04-18T15:30:00' },
      { start: '2023-04-20T13:00:00', end: '2023-04-20T14:30:00' },
      { start: '2023-04-21T09:00:00', end: '2023-04-21T12:00:00' },
      { start: '2023-04-25T11:00:00', end: '2023-04-25T17:00:00' },
      { start: '2023-04-26T10:00:00', end: '2023-04-26T15:00:00' },
    ],
  },
  messages: [
    {
      role: 'assistant',
      content:
        'Hello John, this is Reviving Mind. We would like to schedule your next 45-min coaching session with your coach Jane Jones. Here are some options:\n- Monday, 4/17 at 1-2\n- Tuesday, 4/18 at 11-1\n- Tuesday, 4/18 at 2-3\n\nWould any of these work for you?\n',
      appointment_datetime: undefined,
      is_lead_in: true,
    },
    {
      role: 'user',
      content: 'book any',
      appointment_datetime: undefined,
      is_lead_in: false,
    },
    {
      role: 'assistant',
      content:
        'Great, John! We have scheduled your coaching session with Jane Jones.',
      appointment_datetime: undefined,
      is_lead_in: false,
    },
    {
      role: 'assistant',
      content: 'The system has set the appointment at 1PM on 4/17/2023',
      appointment_datetime: '2023-04-17T13:00:00',
      is_lead_in: false,
    },
  ],
  current_datetime: '2023-04-13T09:00:00',
  appointment_datetime: undefined,
  appointment_link: undefined,
  has_user_messages: true,
  waiting_for_assistant_messages: false,
  waiting_for_latest_explanation: false,
}

export const contexts: Partial<
  Record<
    components['schemas']['APIScenario']['slug'],
    components['schemas']['APIScenarioConversation']
  >
> = {
  scheduling,
}
