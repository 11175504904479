import React, { type FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { get, getAuthorizationConfig, post } from '../configs'
import { type components } from '../configs/schema'
import {
  ErrorsContext,
  ReferencesContext,
  type ReferencesContextType,
} from '../contexts'
import { getJWT, getTypeForm, setJWT, withLogin } from '../helpers'
import { type ChatParams } from '../screens/Chat'

export const ReferencesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { scenario_slug } = useParams<ChatParams>()
  const { handleError } = useContext(ErrorsContext)

  const history = useHistory()
  const [references, setReferences] =
    useState<components['schemas']['APIReferences']>()

  const refreshToken = (): void => {
    const refresh_token = getJWT().refresh_token
    refresh_token &&
      post('/api/demo/auth/refresh-token', { body: { refresh_token } }).then(
        (response) => {
          if (!response.error) {
            const { access_token, refresh_token, expires_in } = response.data
            setJWT(access_token, refresh_token, expires_in)
          } else {
            handleError(response)
          }
        },
      )
  }

  useEffect(() => {
    if (withLogin) {
      get(
        '/api/demo/references',
        getAuthorizationConfig(getJWT().access_token),
      ).then((response) => {
        if (!response.error) {
          setReferences(response.data)
          refreshToken()
        } else {
          handleError(response)
        }
      })
    } else {
      if (!getTypeForm()) {
        history.push('/demo/login')
      } else {
        get('/api/demo/references', {}).then((response) => {
          if (!response.error) {
            setReferences(response.data)
          } else {
            handleError(response)
          }
        })
      }
    }
  }, [])

  const defaultSlug = useMemo(
    () => references?.scenarios.find((scenario) => scenario.is_default)?.slug,
    [references],
  )

  useEffect(() => {
    if (
      !references?.scenarios.find((scenario) => scenario.slug === scenario_slug)
    ) {
      defaultSlug && history.replace(`/demo/chat/${defaultSlug}`)
    }
  }, [references])

  if (
    !references?.scenarios.find((scenario) => scenario.slug === scenario_slug)
  )
    return <></>

  const dataBag: ReferencesContextType = { data: references, refreshToken }
  return (
    <ReferencesContext.Provider value={dataBag}>
      {children}
    </ReferencesContext.Provider>
  )
}
