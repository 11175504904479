import {
  LinearProgress,
  Paper,
  Slide,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import Linkify from 'linkify-react'
import React, {
  type FC,
  type KeyboardEvent,
  type RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { Layout, Message } from '../components'
import { ConfigPanel } from '../components/Layout/ConfigPanel'
import {
  get,
  getAuthorizationHeaders,
  linkifyConfig,
  post,
  put,
} from '../configs'
import { type components } from '../configs/schema'
import {
  ErrorsContext,
  ExplanationContext,
  ReferencesContext,
} from '../contexts'
import { getJWT, getLocalSession, setLocalSession } from '../helpers'
import { StatusCode } from '../hocs'
import { useQuery } from '../hooks'
import { contexts } from '../mocks/contexts'
import { mocks } from '../mocks/conversations'

const ChatWrapper = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  gap: theme.spacing(2),
  maxHeight: `calc(100vh - ${theme.spacing(9)})`,
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(21),
  width: '100%',
}))

export type Slug = components['schemas']['APIScenario']['slug']

export interface ChatParams {
  scenario_slug: Slug
}

export const Chat: FC = () => {
  const { handleError } = useContext(ErrorsContext)
  const { setExplanationLoading, setExplanationData } =
    useContext(ExplanationContext)
  const { scenario_slug } = useParams<ChatParams>()
  const [messages, setMessages] =
    useState<components['schemas']['APIScenarioConversation']['messages']>()
  const [message, setMessage] = useState<string>()
  const [context, setContext] =
    useState<components['schemas']['APIScenarioConversation']>()
  const { data: references, refreshToken } = useContext(ReferencesContext)
  const [redirecting, setRedirecting] = useState(false)
  const [waitingAssistant, setWaitingAssistant] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const query = useQuery()

  const isAutoPlay = query.get('variant') === 'autoplay'

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const inputRef: RefObject<HTMLInputElement> = useRef(null)
  const headers = getAuthorizationHeaders(getJWT().access_token)

  const typingMessage = (
    role: components['schemas']['APIMessage']['role'],
  ): components['schemas']['APIScenarioConversation']['messages'][0] => ({
    role,
    content: !role || role === 'system' ? '' : 'Typing...',
    is_lead_in: false,
  })

  const params = useMemo(
    () => ({
      path: {
        scenario_slug,
        conversation_uid: getLocalSession(scenario_slug),
      },
    }),
    [scenario_slug],
  )

  const scrollToBottom = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }

  useEffect(() => {
    autoScroll && scrollToBottom()
  }, [messages, autoScroll])

  const startConversation = useCallback(() => {
    setRedirecting(true)

    post('/api/demo/scenarios/{scenario_slug}/conversations', {
      params,
      headers,
    }).then((response) => {
      if (!response.error) {
        setLocalSession(scenario_slug, response.data.uid)
        params.path.conversation_uid = response.data.uid
        const content = response.data.waiting_for_assistant_messages
          ? [...response.data.messages, typingMessage('assistant')]
          : response.data.messages
        setMessages(content)
        setContext(response.data)
        setExplanationData(undefined)
        response.data.waiting_for_assistant_messages && waitForAssistant()
        setRedirecting(false)
      } else {
        handleError(response)
      }
    })
  }, [params, scenario_slug])

  const updateConversation = (
    body: components['schemas']['APIUpdateScenarioConversation'],
  ): void => {
    setRedirecting(true)

    put(
      '/api/demo/scenarios/{scenario_slug}/conversations/{conversation_uid}',
      { params, headers, body },
    ).then((response) => {
      if (!response.error) {
        setLocalSession(scenario_slug, response.data.uid)
        params.path.conversation_uid = response.data.uid
        setMessages(response.data.messages)
        setRedirecting(false)
      } else {
        handleError(response)
      }
    })
  }

  const hydrateConversation = (): (() => void) => {
    const defaultMessages =
      mocks[scenario_slug][0].role === 'assistant'
        ? [typingMessage('assistant')]
        : [mocks[scenario_slug][0], typingMessage(mocks[scenario_slug][1].role)]

    setMessages(defaultMessages)
    let index = mocks[scenario_slug][0].role === 'assistant' ? 0 : 1
    setRedirecting(false)

    setContext(contexts[scenario_slug])
    const interval = setInterval(() => {
      if (index < mocks[scenario_slug]?.length) {
        setMessages((prevMessages) => {
          if (
            scrollRef.current &&
            scrollRef.current.scrollHeight - scrollRef.current.scrollTop ===
              scrollRef.current.clientHeight
          ) {
            setAutoScroll(true)
          } else {
            setAutoScroll(false)
          }
          const content = [
            mocks[scenario_slug][index],
            typingMessage(mocks[scenario_slug]?.[index + 1]?.role),
          ]

          if (prevMessages) {
            return [
              ...prevMessages.filter(
                (message) => message.content !== 'Typing...',
              ),
              ...content,
            ]
          } else {
            return content
          }
        })
        index++
      } else {
        clearInterval(interval)
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }

  const waitForAssistant = (): void => {
    setWaitingAssistant(true)
    post(
      '/api/demo/scenarios/{scenario_slug}/conversations/{conversation_uid}/messages/wait-assistant',
      { params, headers },
    ).then((response: any) => {
      if (!response.error) {
        setWaitingAssistant(false)
        setMessages(
          response.data.waiting_for_assistant_messages
            ? [...response.data.messages, typingMessage('assistant')]
            : response.data.messages,
        )
        setContext(response.data)
        response.data.waiting_for_assistant_messages && waitForAssistant()
        response.data.waiting_for_latest_explanation && waitLatestExplanation()
        refreshToken()
      } else {
        handleError(response)
      }
    })
  }

  const waitLatestExplanation = (): void => {
    setExplanationLoading(true)
    post(
      '/api/demo/scenarios/{scenario_slug}/conversations/{conversation_uid}/wait-latest-explanation',
      { params, headers },
    ).then((response) => {
      setExplanationLoading(false)
      if (!response.error) {
        setExplanationData(response.data)
      } else {
        handleError(response)
      }
    })
  }

  const currentReference = useMemo(
    () =>
      references.scenarios.find(
        (reference) => reference.slug === scenario_slug,
      ),
    [references, scenario_slug],
  )

  useEffect(() => {
    // starts new conversation or retrieve old one
    let interval: () => void
    if (currentReference) {
      if (isAutoPlay) {
        interval = hydrateConversation()
      } else {
        setExplanationData(undefined)
        if (!getLocalSession(scenario_slug)) {
          startConversation()
        } else {
          setRedirecting(true)
          get(
            '/api/demo/scenarios/{scenario_slug}/conversations/{conversation_uid}',
            { params, headers },
          )
            .then((response) => {
              if (!response.error) {
                response.data.waiting_for_latest_explanation &&
                  waitLatestExplanation()
                response.data.waiting_for_assistant_messages &&
                  waitForAssistant()
                const content = response.data.waiting_for_assistant_messages
                  ? [...response.data.messages, typingMessage('assistant')]
                  : response.data.messages
                setExplanationData(response.data.latest_explanation)
                setMessages(content)
                setRedirecting(false)
                setContext(response.data)
              } else {
                if (response.response.status === StatusCode.NotFound) {
                  startConversation()
                } else {
                  handleError(response)
                }
              }
            })
            .catch((response) => {
              handleError(response)
            })
        }
      }
    }
    return () => {
      interval?.()
    }
  }, [scenario_slug, params, startConversation, currentReference, isAutoPlay])

  const sendMessage = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' && !event.altKey && !loading) {
      context?.scenario.has_latest_explanation && setExplanationLoading(true)
      setWaitingAssistant(true)
      event.preventDefault()
      // @ts-expect-error value not seen
      const content = event.target?.value
      setMessage('')
      content &&
        setMessages((messages) => {
          if (messages) {
            return [...messages, { role: 'user', content, is_lead_in: false }]
          } else {
            return [{ role: 'user', content, is_lead_in: false }]
          }
        })

      post(
        '/api/demo/scenarios/{scenario_slug}/conversations/{conversation_uid}/messages',
        {
          body: { content },
          params,
          headers,
        },
      ).then((response) => {
        if (!response.error) {
          setMessages([...response.data.messages, typingMessage('assistant')])
          refreshToken()
          setContext(response.data)
          waitForAssistant()
          response.data.scenario.has_latest_explanation &&
            waitLatestExplanation()
        } else {
          context?.scenario.has_latest_explanation &&
            setExplanationLoading(false)
          handleError(response)
        }
      })
    }
  }

  const placeholder = useMemo(
    () => currentReference?.example_patient_messages.join('\r\n'),
    [currentReference],
  )

  const descriptionMessage = useMemo(
    () =>
      currentReference?.description && (
        <Slide easing={{ exit: '0' }} direction="left" in>
          <Message role="system" elevation={3}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              <Linkify options={linkifyConfig}>
                {currentReference.description}
              </Linkify>
            </Typography>
          </Message>
        </Slide>
      ),
    [currentReference],
  )

  const loading = waitingAssistant || redirecting

  return (
    <Layout key={scenario_slug} header={currentReference?.title || ''}>
      <>
        <ChatWrapper ref={scrollRef}>
          {!redirecting && (
            <>
              {descriptionMessage}
              {messages?.map(
                ({ role, content, appointment_datetime }, index) =>
                  content && (
                    <Slide
                      easing={{ exit: '0' }}
                      key={index}
                      direction={role === 'user' ? 'left' : 'right'}
                      in={!redirecting}
                      container={scrollRef.current}
                    >
                      <Message
                        role={appointment_datetime ? 'system' : role}
                        elevation={3}
                      >
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: 'pre-line' }}
                        >
                          <Linkify options={linkifyConfig}>{content}</Linkify>
                        </Typography>
                      </Message>
                    </Slide>
                  ),
              )}
            </>
          )}

          {loading && (
            <LinearProgress
              variant="query"
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: '6px',
              }}
            />
          )}
        </ChatWrapper>
        <Paper
          elevation={3}
          sx={{ m: 3, display: 'flex', mt: -14, zIndex: 999 }}
        >
          <TextField
            sx={{ m: 0 }}
            fullWidth
            ref={inputRef}
            autoFocus
            variant="filled"
            id="multiline-static"
            label="Patient:"
            multiline
            rows="4"
            placeholder={
              loading
                ? 'Please wait for the response from the assistant'
                : context?.has_user_messages
                ? ''
                : placeholder
            }
            margin="normal"
            value={message}
            onChange={(data) => {
              !loading && setMessage(data.target.value)
            }}
            disabled={isAutoPlay}
            onKeyDown={sendMessage}
          />
        </Paper>

        {currentReference && (
          <ConfigPanel
            session_duration_options={references.session_duration_options}
            context={context}
            restartConversation={startConversation}
            updateConversation={updateConversation}
            reference={currentReference}
          />
        )}
      </>
    </Layout>
  )
}
