import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { useMediaQuery } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { type FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { colors } from './configs'
import { ErrorsProvider, SystemNotificationProvider } from './hocs'
import { Screens } from './screens/Screens'

const App: FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: { main: colors.main },
        },
      }),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SystemNotificationProvider>
          <ErrorsProvider>
            <CssBaseline />
            <Screens />
          </ErrorsProvider>
        </SystemNotificationProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
