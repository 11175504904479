import { type components } from '../configs/schema'

type Messages = components['schemas']['APIScenarioConversation']['messages']

const enrollment: Messages = [
  {
    role: 'assistant',
    content: `Hello John! As a successful Sales Executive, we understand the pressure and stress you might be facing. At Reviving Mind, our programs are tailored to help people like you with Stress and Anxiety Disorder through evidence-based Lifestyle Medicine. Our care model focuses on your entire well-being - mind, body, and spirit. We would love to schedule an initial intake session for you at your convenience or feel free to call us at 999-REVIVIN anytime. Alternatively, text back any questions you may have.`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Hi John! We didn't hear back from you, so we wanted to reach out again. We know that as an adventurous individual, balancing your fast-paced lifestyle with work-related stress can be challenging.\nHow are you managing your stress currently?
    `,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `John, I really think we can help you. We are working with numerous people like you, and they have experienced vastly improved outcomes. Let's schedule a convenient time for you to chat with one of our care coordinators, or give us a call at 999-REVIVIN anytime. Feel free to text back with any questions as well.`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Hi John, we know that the stressors of your work and personal life can be overwhelming at times, so we don't want you to put your mental wellness on the back burner! We're here to help you manage your stress and anxiety disorder through our comprehensive programs focused on whole-person care. Would you like to set up a time to chat further over a call? Don't hesitate to text any questions you may have.`,
    is_lead_in: false,
  },
  { role: 'user', content: `Sure tell me more`, is_lead_in: false },
  {
    role: 'assistant',
    content: `Thank you for your interest, John! Reviving Mind offers a unique collaborative care model that integrates chronic disease management and mental health therapy. We focus on evidence-based Lifestyle Medicine with 6 pillars: Physical activity, Nutrition, Substance Control, Sleep, Social Interactions, and Mindfulness/Stress Management. Our programs include bi-weekly individual coaching sessions (30 min) and weekly group therapy "sessions (75 min). Let's schedule an initial intake session at a time that works best for you or call us at 999-REVIVIN to discuss further. Feel free to text back any questions.`,
    is_lead_in: false,
  },
  { role: 'user', content: `That's cool. Who pays for it?`, is_lead_in: false },
  {
    role: 'assistant',
    content: `Great question, John! Reviving Mind will work with your insurance to cover the cost of our services.\nMost people in Massachusetts, Arizona, Minnesota, and Washington have coverage.\nWe'll also try out-of-network benefits for you.\nWe'll inform you if there are any out-of-pocket expenses (typically $0-$75 per month), but we won't bill you before letting you know the cost and getting your explicit agreement.\nFinancial hardship assistance is also available.`,
    is_lead_in: false,
  },
  { role: 'user', content: `Sounds good, give me a call`, is_lead_in: false },
  {
    role: 'assistant',
    content: `We're glad to hear that, John! We'll be happy to give you a call.\nPlease let us know a convenient time for our care coordinator to reach out or feel free to call us at 999-REVIVIN whenever you're ready.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `How is the date after tomorrow at 1?`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Thank you for providing your preferred time! However, our initial intake sessions take place on weekdays only.\nCould you please suggest another date and time between Monday to Friday from 9am to 5pm?`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `Sure, how is the same time tomorrow?`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Great! We have scheduled your initial intake session with our care coordinator for tomorrow, May 12th at 1 pm.\nPlease let us know if you need any further assistance.`,
    is_lead_in: false,
  },
  {
    role: 'system',
    content: `An external system is called with the appointment time of “2023-05-12 13:00” to record the appointment.`,
    is_lead_in: false,
  },
  { role: 'user', content: `Thank you!`, is_lead_in: false },
  {
    role: 'assistant',
    content: `You're welcome, John! We look forward to speaking with you during your initial intake session tomorrow, May 12th at 1 pm.\nIf you need any further assistance or have questions before then, please feel free to reach out.`,
    is_lead_in: false,
  },
]

const insomnia: Messages = [
  { role: 'user', content: "I can't get back to sleep", is_lead_in: false },
  {
    role: 'assistant',
    content:
      "I understand that it can be frustrating when you can't get back to sleep during the night. One of the recommendations of our insomnia program is to get out of bed if you can't fall asleep within 15-20 minutes. This helps to break the association between lying in bed awake and the feeling of frustration. Instead, go to another room and engage in a relaxing activity, such as reading or listening to calming music, until you feel sleepy again. Then, return to bed and try to sleep. It's important to be user with yourself and remember that it might take some time to see improvements in your sleep pattern. Keep following the program's recommendations, and your sleep should get better over time.",
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'How does getting out of bed help?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      "Getting out of bed when you can't sleep helps to retrain your brain and body to associate the bed with sleep. When you stay in bed awake, your mind may start to associate the bed with wakefulness and frustration, which can make it even more difficult to fall asleep. By leaving the bed and engaging in a quiet, relaxing activity, you are giving your mind a chance to reset and become sleepy again. This helps to reinforce the idea that the bed is a place for sleep, and over time, it can improve your overall sleep quality.",
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'But my sleep window is over in 3 hours!!!',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      "I understand that it might seem counterintuitive to get out of bed when your sleep window is short. However, the goal of sleep restriction therapy is to improve your sleep efficiency by consolidating your sleep into a smaller window. By staying in bed awake, you are actually reducing your sleep efficiency, which can make it harder to extend your sleep window in the future. When you get out of bed and engage in a relaxing activity, you are giving your mind a chance to reset and become sleepy again. This can help you fall asleep more quickly when you return to bed, ultimately improving your sleep quality and efficiency. It's important to be user and consistent with the program's recommendations, as it may take some time to see improvements. Remember, the program is challenging, but you can do it!",
    is_lead_in: false,
  },
  { role: 'user', content: 'Ok thanks', is_lead_in: false },
  {
    role: 'assistant',
    content:
      "You're welcome! Remember, it's important to be user and consistent with the program's recommendations. It may take some time to see improvements, but by following the guidelines, you're taking positive steps towards better sleep. If you have any more questions or concerns, feel free to ask.",
    is_lead_in: false,
  },
]

const scheduling: Messages = [
  {
    role: 'assistant',
    content:
      'Hello John, this is Reviving Mind. We would like to schedule your next 45 min coaching session with your coach Jane Jones. Here are some options:\n- Monday, 4/17 at 1-2pm\n- Tuesday, 4/18 at 11am-1pm\n- Tuesday, 4/18 at 2:30-3:30pm',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'I believe I already have a time',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'I apologize for the confusion, John. It seems that you do not have an appointment scheduled at the moment. Please let me know which of the available time slots I provided earlier works best for you, or if you prefer a different time, and I will be happy to schedule your coaching session with Jane Jones.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content:
      'Oops indeed. Is there anything later in the week in the afternoon?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Sure, John. Here are some afternoon options later in the week for your 45-minute coaching session with Jane Jones:\n- Thursday, 4/20 at 1-2:30pm\n- Friday, 4/21 at 9am-12pm (morning option)\nPlease let me know which time works best for you, or if you have any other preferences.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: "Actually let's look into some afternoon times the week after",
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Sure, John. Here are some afternoon options for the week of 4/24 for your 45-minute coaching session with Jane Jones:\n- Tuesday, 4/25 at 11am-5pm\n- Wednesday, 4/26 at 10am-3pm\nPlease let me know which time works best for you, or if you have any other preferences.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: "Let's do Wednesday at 3",
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      "I apologize, but 3pm on Wednesday, 4/26 is not available as it falls outside of the coach's availability. The latest available time on that day is 2:15pm. Would you like to schedule your session at 2:15pm on Wednesday, 4/26, or would you prefer a different time?",
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Sure 2:15 is good',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Great! I have scheduled your 45-minute coaching session with Jane Jones on Wednesday, 4/26 at 2:15pm. Please let me know if you need any further assistance.',
    is_lead_in: false,
  },
  {
    role: 'system',
    content: `An external system is called with the appointment time of "2023-04-26 14:15" to record the appointment.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Thanks!',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      "You're welcome, John! If you have any questions or need further assistance, please don't hesitate to ask.",
    is_lead_in: false,
  },
]

const presidio: Messages = [
  {
    role: 'assistant',
    content:
      'Hello Carla, you have been invited to participate in the Gan & Lee Pharmaceuticals Insulin Glargine Injection clinical trial. I am here to answer any questions and discuss any concerns you may have.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Nice! Why are you doing this trial?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, this clinical trial is being conducted to investigate the immunogenicity, safety, and effectiveness of a new drug, the Sponsor’s Insulin Glargine. We are comparing it with an existing drug, Lantus® insulin glargine, for treating Type 2 Diabetes Mellitus. The goal is to potentially provide more treatment options for individuals with Type 2 Diabetes Mellitus. Your participation is greatly appreciated!',
    is_lead_in: false,
  },
  {
    role: 'user',
    content:
      'What are the risks and possible side effects of taking this new drug?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Dear Carla, participating in this study does involve certain risks. The study drugs may cause side effects, which can range from mild to serious, and in rare cases, could be fatal. Common side effects include hypoglycemia and allergic reactions. There are also potential risks and discomforts associated with the study tests, such as pain, bruising, bleeding, infection, and dizziness from blood samples, discomfort from ECG, and mild pain from CGM Sensor and insulin injections. It's important to note that there may also be unknown risks. Please remember to report any new side effects, injuries, symptoms, or changes in existing symptoms. Your safety is our top priority.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'How do I even know if I am eligible to participate?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, to be eligible for this study, you must be between 18 and 75 years old and have a confirmed diagnosis of Type 2 Diabetes Mellitus. Unfortunately, pregnant women, women planning to become pregnant, and nursing mothers cannot participate in the study. If you meet these criteria, your Study Doctor will schedule the next visit and adjust your medication doses if necessary. The screening visit involves a series of tests to determine your eligibility for the study. We appreciate your interest in our study!',
    is_lead_in: false,
  },
  {
    role: 'user',
    content:
      'What is involved if I participate - what tests, procedures, medication schedule, etc?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Dear Carla, participating in this study involves several tests and procedures. These include blood samples, ECG, and CGM Sensor and insulin injections. You will also be given a study medication that you must take as directed. It's important that you attend all scheduled visits, which will occur at various times during the study. Your main responsibilities as a participant are to provide accurate health information, report any changes in your health, and inform us if you or your partner become pregnant. We appreciate your willingness to participate in this study and your commitment to your health.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `How long is the study and how many visits?`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, the study will last for 26 weeks, with a follow-up phone call 4 weeks after the study ends. There are 9 visits in total: the Screening Visit, Randomization, and 7 more visits at weeks 2, 4, 8, 12, 16, 20, and 26. Additionally, there will be telephone contacts at weeks 6, 10, 14, 18, and 22. We understand this is a significant commitment and we appreciate your willingness to participate.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Can I stop participating at any time? What are the consequences?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, you absolutely have the right to withdraw from the study at any time and for any reason, without any penalty or loss of benefits. If you decide to leave, you will be asked to undergo a final evaluation visit. This is to ensure your safety and health. We appreciate your participation and understand that circumstances can change.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `That's good to know.`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `I'm glad you found the information helpful, Carla! If you have any other questions in the future, don't hesitate to ask. We're here to support you.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Yeah, how much will this disrupt my normal, daily routine?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, participating in this study may require some adjustments to your daily routine. You will need to attend all scheduled visits, take the study medication as directed, and report any changes to your health. The trial may also affect your ability to concentrate and react, which could pose a risk when driving or using machines. We understand that this is a significant commitment and we appreciate your willingness to participate. Your health and safety are our top priority.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `What happens if I'm assigned the placebo - can I still get the drug later?`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, in this particular study, there is no placebo group. Participants will be randomly assigned to receive either the Sponsor’s Insulin Glargine or Lantus®. Both are active medications used to treat Type 2 Diabetes Mellitus. Therefore, regardless of the group you are assigned to, you will receive an active medication. We appreciate your commitment to this study.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content:
      'Makes sense! Will I have to pay for any medical care, tests, the drug itself?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `Dear Carla, you won't have to pay anything to participate in this study. All the study-related costs, including the study medication and all procedures, will be covered. Additionally, you'll be reimbursed for any reasonable travel expenses. We truly appreciate your willingness to participate in this study and we want to make sure that it doesn't impose any financial burden on you.`,
    is_lead_in: false,
  },
  {
    role: 'user',
    content: 'Good! Can I get paid for participating in the study?',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, while we deeply appreciate your willingness to participate in this study, we do not offer payment for participation. However, we understand that attending the study visits may involve travel costs, and we will reimburse you for any reasonable travel expenses. We are committed to ensuring that your participation in this study does not impose any financial burden on you.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content: `It's still a bummer, but I understand`,
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content:
      'Dear Carla, we understand and appreciate your feelings. Your participation is invaluable and contributes significantly to medical research and potentially helping many people. If you have any other concerns or questions, please feel free to share.',
    is_lead_in: false,
  },
  {
    role: 'user',
    content:
      'Will do. This was really informative and thanks for responding so quickly!',
    is_lead_in: false,
  },
  {
    role: 'assistant',
    content: `You're very welcome, Carla! I'm glad I could help. Don't hesitate to reach out if you have any more questions in the future. Best of luck with your decision!`,
    is_lead_in: false,
  },
]

export const mocks: Record<
  components['schemas']['APIScenario']['slug'],
  Messages
> = {
  enrollment,
  insomnia,
  scheduling,
  presidio,
  router: [],
  rescheduling: [],
  reactivation: [],
  study_update: [],
}
