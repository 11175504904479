import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import { logoImage } from '../../assets'
import { configWidth } from './ConfigPanel'
import { LeftNavigation } from './LeftNavigation'

export const drawerWidth = 260

const LeftPanel = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    border: 'none',
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}))

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  marginRight: `${configWidth}px`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  background: 'linear-gradient(to bottom, #7366FFaa, #BBA6FF, #FFD1AA)',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}))

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: `calc(100% - ${drawerWidth}px)`,
  zIndex: '1001',
  boxShadow: theme.shadows[3],
  background: theme.palette.primary.dark,
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  boxShadow: theme.shadows[3],
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  background: theme.palette.primary.dark,
  marginBottom: '-1px',
}))

export const Layout: React.FC<{
  children: React.ReactNode
  header: string
}> = ({ children, header }) => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar position="fixed">
      <Toolbar>
        <Typography
          style={{ marginRight: `${configWidth}px`, width: '100%' }}
          variant="h5"
          noWrap
          component="div"
          align="center"
        >
          {header}
        </Typography>
      </Toolbar>
    </AppBar>
    <LeftPanel
      PaperProps={{ elevation: 3 }}
      variant="persistent"
      anchor="left"
      open
    >
      <DrawerHeader>
        {/* FIXME: Do not pass absolute height to the logo in favor of relative value */}
        <img
          height="52"
          style={{ margin: '0 auto' }}
          src={logoImage}
          alt="logo"
        />
      </DrawerHeader>
      <Divider />

      <LeftNavigation />
    </LeftPanel>
    <Main>{children}</Main>
  </Box>
)
