import { Alert, Snackbar } from '@mui/material'
import React, { type FC, useContext } from 'react'

// import { NOTIFICATION_HIDE_DURATION } from '../../../constants'
import {
  SystemNotificationsContext,
  type SystemNotificationsType,
} from '../../contexts'

export const SystemNotifications: FC = () => {
  const { isOpen, notification, onCloseNotification } =
    useContext<SystemNotificationsType>(SystemNotificationsContext)

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={onCloseNotification}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert color={notification.severity}>{notification.message}</Alert>
    </Snackbar>
  )
}
