import { ChatBubble, PlayArrow } from '@mui/icons-material'
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material'
import React, { type FC, useContext } from 'react'
import * as reactRouterDom from 'react-router-dom'

import { ReferencesContext } from '../../contexts'
import { mocks } from '../../mocks/conversations'
import { type Slug } from '../../screens/Chat'
import { drawerWidth } from './Layout'

const NavigationLink = styled(reactRouterDom.NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  borderBottom: `3px solid ${theme.palette.divider}`,
  span: {
    fontSize: '18px !important',
  },
  '&.active': {
    color: theme.palette.getContrastText(theme.palette.primary.light),
    '&>li': {
      background: theme.palette.primary.light,
      '& p': {
        color: theme.palette.getContrastText(theme.palette.primary.light),
      },
    },
  },
}))
const Item = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: 'transparent',
}))

const DemoButton = styled(Button)(({ theme }) => ({
  zIndex: 9999,
  position: 'absolute',
  bottom: 16,
  left: 16,
  justifyContent: 'flex-start',
  width: `calc(${drawerWidth}px - 32px)`,
}))

export const LeftNavigation: FC = () => {
  const { data } = useContext(ReferencesContext)
  const history = reactRouterDom.useHistory()

  const handlePlayDemo = (event: React.SyntheticEvent, slug: Slug): void => {
    event.preventDefault()
    event.stopPropagation()
    history.push(`/demo/chat/${slug}?variant=autoplay`)
  }

  return (
    <List sx={{ padding: 0 }}>
      {data?.scenarios.map(({ slug, title }) => (
        <NavigationLink
          key={slug}
          activeClassName="active"
          to={`/demo/chat/${slug}`}
        >
          <Item key={slug} disablePadding>
            <ListItemButton
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingBottom: mocks[slug]?.[0] ? 7 : 2,
              }}
            >
              <ListItemText sx={{ marginBottom: 1 }} primary={title} />
              <Button
                sx={{ justifyContent: 'flex-start' }}
                startIcon={<ChatBubble />}
                variant="contained"
                fullWidth
                size="small"
              >
                Live conversation
              </Button>
              {mocks[slug]?.[0] && (
                <DemoButton
                  color="inherit"
                  variant="contained"
                  fullWidth
                  size="small"
                  startIcon={<PlayArrow />}
                  onClick={(event) => {
                    handlePlayDemo(event, slug)
                  }}
                >
                  Watch Demo
                </DemoButton>
              )}
            </ListItemButton>
          </Item>
        </NavigationLink>
      ))}
    </List>
  )
}
