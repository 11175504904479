import { type AlertColor } from '@mui/material'
import React, { type FC, useState } from 'react'

import { SystemNotifications } from '../components'
import { SystemNotificationsContext } from '../contexts'
export interface NotificationType {
  message: string
  severity: AlertColor
}

export const SystemNotificationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const initNotificationState: NotificationType = {
    message: '',
    severity: 'info',
  }
  const [notification, setNotification] = useState(initNotificationState)

  const sendNotification = (message: string, severity: AlertColor): void => {
    setIsOpen(true)
    if (typeof message === 'string') {
      setNotification({
        message,
        severity,
      })
    }
  }

  const sendErrorNotification = (message: string): void => {
    sendNotification(message, 'error')
  }

  const sendSuccessNotification = (message: string): void => {
    sendNotification(message, 'success')
  }

  const sendWarningNotification = (message: string): void => {
    sendNotification(message, 'warning')
  }

  const sendInfoNotification = (message: string): void => {
    sendNotification(message, 'info')
  }

  const onCloseNotification = (): void => {
    setIsOpen(false)
  }

  const notificationBag = {
    isOpen,
    notification,
    sendErrorNotification,
    sendSuccessNotification,
    sendWarningNotification,
    onCloseNotification,
    sendInfoNotification,
  }

  return (
    <SystemNotificationsContext.Provider value={notificationBag}>
      <SystemNotifications />
      {children}
    </SystemNotificationsContext.Provider>
  )
}
