import { type components } from './../configs/schema'

export const withLogin: boolean = process.env.REACT_APP_WITH_LOGIN === 'true'

export const setLocalSession = (
  slug: components['schemas']['APIScenario']['slug'],
  uid: string,
): void => {
  localStorage.setItem(slug, uid)
}

export const getLocalSession = (
  slug: components['schemas']['APIScenario']['slug'],
): string => localStorage.getItem(slug) || ''

export enum JWT {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
  expires_in = 'expires_in',
}

export const setJWT = (
  access_token: string,
  refresh_token: string,
  expires_in: number,
): void => {
  localStorage.setItem(JWT.access_token, access_token)
  localStorage.setItem(JWT.refresh_token, refresh_token)
  localStorage.setItem(JWT.expires_in, expires_in.toString())
}

export const getJWT = (): {
  access_token: string
  refresh_token: string
  expires_in: number
} => {
  const access_token = localStorage.getItem(JWT.access_token) || ''
  const refresh_token = localStorage.getItem(JWT.refresh_token) || ''
  const expires_in = parseInt(localStorage.getItem(JWT.expires_in) || '0')
  return { access_token, refresh_token, expires_in }
}

export const deleteJWT = (): void => {
  localStorage.removeItem(JWT.access_token)
  localStorage.removeItem(JWT.refresh_token)
  localStorage.removeItem(JWT.expires_in)
}

export const setTypeForm = (token: string): void => {
  localStorage.setItem('TypeForm', token)
}

export const getTypeForm = (): string => localStorage.getItem('TypeForm') || ''

export const deleteTypeForm = (): void => {
  localStorage.removeItem('TypeForm')
}
