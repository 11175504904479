import { createContext } from 'react'

import { type NotificationType } from '../hocs'

export interface SystemNotificationsType {
  isOpen: boolean
  notification: NotificationType
  sendErrorNotification: (error: string) => void
  sendSuccessNotification: (message: string) => void
  sendWarningNotification: (message: string) => void
  sendInfoNotification: (message: string) => void
  onCloseNotification: () => void
}

export const SystemNotificationsContext =
  createContext<SystemNotificationsType>(undefined!)
