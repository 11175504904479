import aidemoLogoImage from './logo-aidemo.png'
import defaultLogoImage from './logo-default.svg'

export { default as infographicImage } from './infographic.png'

const { REACT_APP_APP_LOGO } = process.env

const logoImage =
  REACT_APP_APP_LOGO === 'aidemo' ? aidemoLogoImage : defaultLogoImage

export { logoImage }
