import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material'
import {
  DatePicker,
  DigitalClock,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import React, { type FC, useCallback } from 'react'

interface DateObject {
  start: string
  end: string
}

interface CoachAvailabilityType {
  availability: Array<{ start: string; end: string }>
  handleUpdate: (
    newValue: dayjs.Dayjs,
    index: number,
    prefix: 'start' | 'end',
    isTime?: boolean,
  ) => void
  handleDeleteAvailability: (index: number) => void
  handleAddAvailability: () => void
}

export const CoachAvailability: FC<CoachAvailabilityType> = ({
  availability,
  handleUpdate,
  handleDeleteAvailability,
  handleAddAvailability,
}) => {
  const [expanded, setExpanded] = React.useState<number | false>(false)

  const toggleAccordion =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false)
    }

  const getSlot = useCallback(
    (date: DateObject, index: number): JSX.Element => {
      const startDateTime = dayjs(date.start).locale('en')
      const endDateTime = dayjs(date.end).locale('en')
      const formattedString = `${startDateTime.format(
        'dddd, M/D',
      )} at ${startDateTime.format('h:mma')} - ${endDateTime.format('h:mma')}`

      return (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          key={index}
          expanded={expanded === index}
          onChange={toggleAccordion(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography key={formattedString} variant="body1">
              {formattedString}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={['DatePicker', 'DigitalClock', 'DigitalClock']}
              >
                <DatePicker
                  sx={{ width: '100%', marginBottom: '-16px' }}
                  label="Date"
                  value={dayjs(date.start)}
                  onChange={(newValue) => {
                    newValue && handleUpdate(newValue, index, 'start')
                  }}
                />
                <Box sx={{ display: 'flex', height: 80, mb: 0 }}>
                  <DigitalClock
                    value={dayjs(date.start)}
                    onChange={(newValue) => {
                      newValue && handleUpdate(newValue, index, 'start', true)
                    }}
                  />
                  <DigitalClock
                    value={dayjs(date.end)}
                    onChange={(newValue) => {
                      newValue && handleUpdate(newValue, index, 'end')
                    }}
                  />
                </Box>
                <Button
                  disabled={availability.length <= 1}
                  fullWidth
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    handleDeleteAvailability(index)
                    setExpanded(false)
                  }}
                >
                  Delete
                </Button>
              </DemoContainer>
            </LocalizationProvider>
          </AccordionDetails>
        </Accordion>
      )
    },
    [availability, expanded],
  )

  return (
    <>
      <div>{availability.map((date, index) => getSlot(date, index))}</div>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          handleAddAvailability()
          setTimeout(() => {
            setExpanded(availability.length)
          }, 50)
        }}
      >
        Add Availability slot
      </Button>
    </>
  )
}
