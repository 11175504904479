import { Button, Dialog, styled, Typography } from '@mui/material'
import React, { type FC, useState } from 'react'

const Header = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),
  padding: theme.spacing(3),
}))

export const PartnershipModal: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Button
        size="medium"
        variant="contained"
        onClick={() => {
          setIsModalOpen(true)
        }}
        sx={{
          position: 'fixed',
          top: 14,
          right: 16,
          width: 368,
          zIndex: 1300,
        }}
      >
        Interested in Partnering!
      </Button>
      <Dialog
        onClose={() => {
          setIsModalOpen(false)
        }}
        keepMounted={false}
        open={isModalOpen}
      >
        <Header variant="h5" align="center">
          Interested in Partnering!
        </Header>
        <iframe
          width={600}
          height={870}
          src="https://calendly.com/rahulnh/30min"
        />
      </Dialog>
    </>
  )
}
