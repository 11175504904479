import {
  Button,
  Dialog,
  Divider,
  keyframes,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import React, {
  type FC,
  type FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { getAuthorizationConfig, post } from '../../configs'
import { ErrorsContext, SystemNotificationsContext } from '../../contexts'
import { getJWT } from '../../helpers'
import { type ChatParams } from '../../screens/Chat'

const jiggleAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
`

const JigglingButton = styled(Button)<{
  jiggling: string
}>(({ theme, jiggling }) => ({
  animation: jiggling ? `${jiggleAnimation} 0.5s` : 'none',
}))

const Form = styled('form')(({ theme }) => ({
  width: '600px',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
}))

const Header = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),
  padding: theme.spacing(3),
}))

export const FeedbackModal: FC = () => {
  const { handleError } = useContext(ErrorsContext)
  const { sendSuccessNotification } = useContext(SystemNotificationsContext)

  const { scenario_slug } = useParams<ChatParams>()

  const [jiggling, setIsJiggling] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsJiggling('true')
      setTimeout(() => {
        setIsJiggling('')
      }, 500) // Duration of the jiggle animation (0.5s)
    }, 60000) // 60 seconds

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setIsSending(true)
    const target = event.target as HTMLFormElement
    const email = target.email.value
    const fullname = target.fullname.value
    const message = target.message.value

    post('/api/demo/scenarios/{scenario_slug}/feedback', {
      ...getAuthorizationConfig(getJWT().access_token),
      params: { path: { scenario_slug } },
      body: {
        email,
        full_name: fullname,
        message,
      },
    }).then((response) => {
      if (!response.error) {
        sendSuccessNotification('Feedback successfully sent, thank you!')
        setIsModalOpen(false)
        setIsSending(false)
      } else {
        handleError(response)
        setIsSending(false)
      }
    })
  }

  return (
    <>
      <JigglingButton
        jiggling={jiggling}
        size="small"
        variant={jiggling ? 'contained' : 'outlined'}
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Leave Feedback
      </JigglingButton>
      <Dialog
        onClose={() => {
          setIsModalOpen(false)
        }}
        keepMounted={false}
        open={isModalOpen}
      >
        <Header variant="h5" align="center">
          Leave Feedback
        </Header>
        <Form onSubmit={handleSubmit}>
          <Divider />
          <TextField
            disabled={isSending}
            name="email"
            label="Email"
            variant="outlined"
            type="email"
            autoFocus
          />
          <TextField
            disabled={isSending}
            name="fullname"
            label="Full Name"
            variant="outlined"
            type="text"
          />
          <TextField
            disabled={isSending}
            multiline
            rows={4}
            name="message"
            required
            label="Message"
            variant="outlined"
            type="text"
          />
          <Button
            disabled={isSending}
            size="large"
            variant="contained"
            type="submit"
          >
            {isSending ? 'Sending feedback...' : 'Send Feedback'}
          </Button>
        </Form>
      </Dialog>
    </>
  )
}
