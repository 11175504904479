import React, { type FC, useState } from 'react'

import { type components } from '../configs/schema'
import { ExplanationContext, type ExplanationContextType } from '../contexts'

export const ExplanationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [explanationLoading, setExplanationLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const [data, setExplanationData] =
    useState<components['schemas']['APILatestExplanation']>()

  const dataBag: ExplanationContextType = {
    visible,
    explanationLoading,
    data,
    setVisible,
    setExplanationLoading,
    setExplanationData,
  }

  return (
    <ExplanationContext.Provider value={dataBag}>
      {children}
    </ExplanationContext.Provider>
  )
}
