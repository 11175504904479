import { Grid, Hidden, Paper, styled } from '@mui/material'
import { Widget } from '@typeform/embed-react'
import React, { type FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { infographicImage, logoImage } from '../assets'
import { getTypeForm, setTypeForm } from '../helpers'

const Form = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(to bottom, #7366FF, #BBA6FF, #FFD1AA)',
  '& iframe': {
    borderRadius: '0px !important',
  },
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    '& > img': {
      height: theme.spacing(12),
      padding: theme.spacing(2),
      background: theme.palette.primary.dark,
    },
  },
}))

export const Typeform: FC = () => {
  const history = useHistory()

  useEffect(() => {
    getTypeForm() && history.replace('/demo/chat')
  }, [])

  if (getTypeForm()) return <></>

  return (
    <Form>
      <Paper elevation={3}>
        <img src={logoImage} alt="logo" />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Widget
            id="CBweVxKb"
            autoFocus
            keepSession
            disableScroll
            onSubmit={(payload) => {
              setTypeForm(payload.responseId)
              history.replace('/demo/chat')
            }}
            height={675}
            width={600}
          />
          <Hidden lgDown>
            <img src={infographicImage} width="600px" alt="infographic" />
          </Hidden>
        </Grid>
      </Paper>
    </Form>
  )
}
