import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { type Dayjs } from 'dayjs'
import React, { type FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { type components } from '../../configs/schema'
import { deleteJWT, withLogin } from '../../helpers'
import { useQuery } from '../../hooks'
import { legends } from '../../mocks/legends'
import { type ChatParams } from '../../screens/Chat'
import { CoachAvailability, FeedbackModal, PartnershipModal } from '../Chat'
import { Explanation } from './Explanation'
import { Legend } from './Legend'

export const configWidth = 400

const Link = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const RightPanel = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: `${configWidth}px`,
  minWidth: `${configWidth}px`,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(11),
  gap: theme.spacing(2),
  height: '100vh',
  overflow: 'auto',
}))

const ButtonsWrapper = styled('div')(({ theme }) => ({
  justifyContent: 'flex-end',
  flexGrow: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),
}))

const API_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const ConfigPanel: FC<{
  session_duration_options: components['schemas']['APIReferences']['session_duration_options']
  reference: components['schemas']['APIReferences']['scenarios'][0]
  context?: components['schemas']['APIScenarioConversation']
  restartConversation: () => void
  updateConversation: (
    body: components['schemas']['APIUpdateScenarioConversation'],
  ) => void
}> = ({
  session_duration_options,
  reference,
  context,
  restartConversation,
  updateConversation,
}) => {
  const { scenario_slug } = useParams<ChatParams>()
  const query = useQuery()
  const history = useHistory()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [settings, setSettings] = useState<
    components['schemas']['APIUpdateScenarioConversation'] | undefined
  >()

  const isAutoPlay = query.get('variant') === 'autoplay'

  useEffect(() => {
    if (context) {
      const {
        session_duration,
        current_datetime,
        coach,
        appointment_datetime,
      } = context

      setSettings({
        session_duration,
        current_datetime,
        appointment_datetime,
        coach,
      })
    }
  }, [context])

  const handleUpdate = (): void => {
    settings && updateConversation(settings)
    setIsDirty(false)
  }

  const handleSessionDuration = (event: SelectChangeEvent): void => {
    setIsDirty(true)
    setSettings((prevSettings) => {
      const value = parseInt(
        event.target.value.toString(),
      ) as components['schemas']['APIUpdateScenarioConversation']['session_duration']
      return {
        ...(prevSettings as components['schemas']['APIUpdateScenarioConversation']),
        session_duration: value,
      }
    })
  }

  const handleDateTime = (
    value: Dayjs | null,
    prefix: 'current_datetime' | 'appointment_datetime',
  ): void => {
    setIsDirty(true)
    const formattedValue = value?.format(API_FORMAT)

    formattedValue &&
      setSettings((prevSettings) => ({
        ...(prevSettings as components['schemas']['APIUpdateScenarioConversation']),
        [prefix]: formattedValue,
      }))
  }

  const handleAvailabilityUpdate = (
    newValue: dayjs.Dayjs,
    index: number,
    prefix: 'start' | 'end',
    isTime?: boolean,
  ): void => {
    setIsDirty(true)
    setSettings((prevSettings) => {
      if (!prevSettings) {
        return context
      } else {
        const availability = [...prevSettings.coach.availability]
        if (prefix === 'start') {
          availability[index] = {
            start: newValue.format(API_FORMAT),
            end: isTime
              ? newValue.add(1, 'hour').format(API_FORMAT)
              : prevSettings.coach.availability[index].end,
          }
        } else {
          availability[index] = {
            start: prevSettings.coach.availability[index].start,
            end: newValue.format(API_FORMAT),
          }
        }

        return {
          ...prevSettings,
          coach: { availability },
        }
      }
    })
  }

  const handleDeleteAvailability = (index: number): void => {
    setIsDirty(true)
    setSettings((prevSettings) => {
      if (!prevSettings) {
        return context
      } else {
        const newAvailability = [...prevSettings?.coach.availability]
        newAvailability.splice(index, 1)
        return { ...prevSettings, coach: { availability: newAvailability } }
      }
    })
  }

  const handleAddAvailability = (): void => {
    setIsDirty(true)
    setSettings((prevSettings) => {
      if (!prevSettings) {
        return context
      } else {
        const newAvailability = [...prevSettings.coach.availability]
        newAvailability.push({
          start: dayjs().set('minutes', 0).set('seconds', 0).format(API_FORMAT),
          end: dayjs()
            .set('minutes', 0)
            .set('seconds', 0)
            .add(2, 'hour')
            .format(API_FORMAT),
        })
        return { ...prevSettings, coach: { availability: newAvailability } }
      }
    })
  }

  return (
    <RightPanel elevation={3}>
      {reference.has_latest_explanation && <Explanation />}
      {reference.display_legend &&
        (legends[scenario_slug] ? (
          <Legend />
        ) : (
          settings && (
            <>
              <Typography variant="body1">
                Patient: {context?.patient.full_name}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Current Time"
                  value={dayjs(settings.current_datetime)}
                  onChange={(value) => {
                    handleDateTime(value, 'current_datetime')
                  }}
                />
              </LocalizationProvider>

              <FormControl fullWidth>
                <InputLabel id="session-duration">Session Duration</InputLabel>
                <Select
                  labelId="session-duration"
                  id="session-duration-select"
                  value={settings.session_duration?.toString()}
                  label="Session Duration"
                  onChange={handleSessionDuration}
                >
                  {session_duration_options.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body1">
                Coach: {context?.coach.full_name}
              </Typography>
              {settings.appointment_datetime && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    label="Current Appointment"
                    onChange={(value) => {
                      handleDateTime(value, 'appointment_datetime')
                    }}
                    value={dayjs(settings.appointment_datetime)}
                  />
                </LocalizationProvider>
              )}
              {context?.appointment_link && (
                <Typography variant="subtitle1">
                  Appointment Link:{' '}
                  <Link href={context.appointment_link} target="_blank">
                    Zoom
                  </Link>
                </Typography>
              )}
              <Typography variant="subtitle1">
                {context?.coach.first_name}&apos;s availability:
              </Typography>
              <CoachAvailability
                handleUpdate={handleAvailabilityUpdate}
                handleDeleteAvailability={handleDeleteAvailability}
                availability={settings.coach.availability}
                handleAddAvailability={handleAddAvailability}
              />

              <Button
                size="small"
                variant={isDirty ? 'contained' : 'outlined'}
                disabled={!isDirty}
                onClick={handleUpdate}
              >
                Update Conversation
              </Button>
            </>
          )
        ))}
      <ButtonsWrapper>
        <FeedbackModal />
        <Button
          size="small"
          variant="outlined"
          disabled={isAutoPlay}
          onClick={restartConversation}
        >
          Restart Conversation
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={() => {
            withLogin ? deleteJWT() : localStorage.clear()
            history.replace('/demo/login')
          }}
        >
          Logout
        </Button>
      </ButtonsWrapper>
      <PartnershipModal />
    </RightPanel>
  )
}
