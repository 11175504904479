export * from './colors'
export * from './fetch'
export * from './linkify'

const { REACT_APP_AUTHORIZATION_HEADER } = process.env

export const getAuthorizationHeader = (): string =>
  REACT_APP_AUTHORIZATION_HEADER ?? 'Authorization'

export const getAuthorizationHeaders = (
  accessToken: string,
): Record<string, string> => ({
  [getAuthorizationHeader()]: `Bearer ${accessToken}`,
})

export const getAuthorizationConfig = (
  accessToken: string,
): { headers: Record<string, string> } => ({
  headers: getAuthorizationHeaders(accessToken),
})
